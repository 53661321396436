import React from "react";
import Slider from "react-slick";
import "../Styles/Testimonials.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    text: "Implementing FrothTestOps was a turning point for the testing process in our company. All the necessary testing plans, defect management, and so much more... are covered throughout the application. Thanks to FrothTestOps, our testers have pulled together and become much more efficient and coordinated.",
    title: "Senior Test Manager",
  },
  {
    text: "Thank you Robotico for your Froth Desk. You are the best! I have no words about how grateful I am. The automation of our help-desk operations has been dramatically improved by its features and the ease of its interface. From now on, I consider FrothDesk to be a must-have tool for my operation and customer service team.",
    title: "Technical Architect",
  },
  {
    text: "FrothCRM was the real game changer. It changes the way we lead and deal our tracking. Due to this, we get the accurate forecasting, manage the sales funnel, and at the same time enjoy all the customer interactions. Also, Thanks to the reminders and notifications our sales team can maintain their work plan smoothly.",
    title: "Lead Developer",
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="d-flex justify-content-center">
                <div className="card h-100" style={{border:'none'}}>
                  <div className="card-body text-left">
                    <div className="stars">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <p className="card-text">{testimonial.text}</p>
                    <div className="d-flex justify-content-end">
                        <p className="card-subtitle">
                          - {testimonial.title}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
